import { CardInterface } from "./Resources";

export const box_data: CardInterface[] = [
    {
        id: 7,
        name: "Terminal",
        isDone: false,
        link: "https://www.ozpaker.org/terminal",
        description:
            "Web-based command line.\nType 'help' for a list of commands.",
        tag: "Web",
        tagRight: "2020"
    },

    {
        id: 6,
        name: "Sensors",
        isDone: false,
        link: "https://play.google.com/store/apps/details?id=org.ozpaker.sensors",
        description:
            "Provides users with access to raw smartphone sensor data coming phone accelerometer and magnetometer. More sensor modules coming soon.",
        tag: "Android",
        tagRight: "2020"
    },

    {
        id: 5,
        name: "Colors",
        isDone: false,
        link: "https://www.ozpaker.org/colors",
        description:
            'Display a fullscreen color.\nrgb: "r,g,b" | hex: "rrggbb" | "name"',
        tag: "Web",
        tagRight: "2019"
    },

    {
        id: 4,
        name: "Minimal Grade Calculator",
        isDone: false,
        link: "https://play.google.com/store/apps/details?id=org.ozpaker.minimalgradecalculator",
        description:
            "Calculate how assignments and exams affect your grade or choose to calculate your current GPA.",
        tag: "Android",
        tagRight: "2018"
    },

    {
        id: 3,
        name: "Follower Audit for Instagram",
        isDone: false,
        link: "https://www.ozpaker.org/instagram",
        description:
            "Check who's not following you back. Due to Instagram's API changes, lists must now be manually updated.",
        tag: "Web",
        tagRight: "2019"
    },

    {
        id: 2,
        name: "CS:GO Map Voting",
        isDone: false,
        link: "https://www.ozpaker.org/csgo",
        description:
            "Vote for today's CS:GO map pool. New accounts are no longer allowed. Please use your existing account to vote.",
        tag: "Web",
        tagRight: "2020"
    },

    {
        id: 1,
        name: "Bruh Button",
        isDone: false,
        link: "https://www.ozpaker.org/bruh",
        description: "A button for bruh moments.\nNo longer supported.",
        tag: "Web",
        tagRight: "2018"
    },
];
